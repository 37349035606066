.about_content {
    margin-top: 150px;
}

.about_header {
    text-align: center;
    font-style: italic;
    color: rgba(255, 255, 255, 0.5);

    position: relative;
    top: 50%;
    left: 50%;
    
    
    transform: translate(-50%, -50%);

    height: 2vh;
    width: 20vw;
}

#header_container {
    height: 2vh;
    width: 40vw;
}

#header_img {
    position: absolute;

    top: 22%;
    left: 50%;

    transform: translate(-50%, -50%);

    height: 227px;
    width: 250px;

    pointer-events: none;
    opacity: 0.3;
}

#about_text_container {
    margin-top: 10vh;
    width: 40vw;
}


#links_container {

}

.about_text {
    color: white;

    font-size: 1.1em;
}