.mb_mission_view {
    margin-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    width: 95vw;
    max-width: 465px;
}

.mb_mv_desc {
    font-size: 24px;
    height: 60vh;
    overflow: auto;
}

.mb_mv_panelbtn {
    border: 1px solid white;
    background-color: #141414;
    color: white;

    height: 32px;
    font-size: 20px;
    width: 47%;
}

.mb_mv_panelbtn:hover {
    background-color: #ffdda9;
    color: #FF9900;
    border-color: #FF9900;
}

.mb_mv_panelbtn_selected {
    color: #FF9900 !important;
    border-color: #FF9900 !important;
}

.mb_extra_label {
    color: white;
    font-size: 24px;
    pointer-events: all;
}

.mb_extra_value {
    color: #ffdda9;;
    font-size: 24px;
}

.mb_smaller_text {
    font-size: 20px !important;
    margin-top: auto;
    margin-bottom: auto;
}

.mb_pilot_check_container {
    position: fixed;
    border: 1px solid white;
    background-color: #141414;

    height: 85vh;
    /* min-width: 360px; */

    top: 47%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.mb_pilot_check_backbtn {
    border: 1px solid white;
    background-color: #141414;
    color: white;

    width: 100px;

    margin-bottom: 10px;
}

.mb_pilot_check_backbtn:hover {
    color: #ffdda9;
    border-color: #ffdda9;
}

.mb_pilot_scroll {
    height: 90%;
    overflow: auto;
}