.home_content {
    margin-top: 125px;
}

.home_menu {
    margin-left: 75px;
    width: 25vw;
    min-width: 250px
}

.__m_content {
    border-color: #FF9900;
    
    min-width: 800px !important;
    height: 650px;
}

.__m_list_header {
    font-size: 25px;
    height: 30px;
    background-color: #FF9900;
    color: black;

    width: 560px;
}

.__m_list {
    font-size: 24px;
    height: 532px;
    overflow-y: auto;
}

.__m_mission_item {
    background-color: transparent;
    border: none;
    text-align: left;
    color: white;
}

.__m_mission_item:hover {
    background-color: #ffdda9;
    color: black;
}

.__m_mission_item__complete {
    color: #00cc66 !important;
}

.__m_mission_item__current {
    color: #0096FF !important;
}

.__m_options {
    font-size: 24px;
    color: black;
    background-color: #FF9900;
    
    height: 612px;
    width: 240px;
}
.__m_options_label {
    width: 175px;
}

.__m_item_0 {
    width: 10px;
    overflow: hidden;
    white-space: nowrap;
}
.__m_item_1 {
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
}
.__m_item_2 {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
}
.__m_item_3 {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
}
.__m_item_4 {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.__n_content {
    border-color: white;

    width: 400px !important;
    height: 400px;
}

.newsgraphic_container {
    width: 250px;
    height: 250px;

    margin: auto;
}

.newsgraphic {
    width: 100%;
    height: 100%;

    pointer-events: none;

    filter: invert(70%) sepia(34%) saturate(6990%) hue-rotate(2deg) brightness(107%) contrast(103%);
}