.__mb_ch_prv {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 80vw;
    max-width: 465px;
    height: 80vh;
    max-height: 700px;

    border: solid 1px white;
    background-color: #141414;

    z-index: 5;
}

.__mb_cp_title {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;

    font-size: 30px;
}

.__mb_cp_subtitle {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;

    font-size: 26px;
}

.__mb_cp_img_wrap {
    /* overflow: scroll; */
    
}

.__mb_cp_img {
    float: right;
    height: 100px;
    width: 100px;
    margin-right: 20px;
}

.__mb_cp_dimg {
    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%);
}

.__mb_cp_desc {
    /* width: 100%; */
    /* height: 90%; */
    font-size: 30px;
    /* overflow: scroll; */
}

.__mb_cp_btn {
    color: white;
    width: 200px;
    border: solid 1px white;
    background-color: transparent;
}

.__mb_cp_btn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.__mb_cp_inpt {
    border: 1px solid white;
    background-color: #141414;

    color: white;
    width: 200px;
}

.__mb_cp_inpt:focus {
    outline: none;
}