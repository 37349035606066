#plusbg {
    font-size: 25px;
    font-family: 'Work Sans', sans-serif;
    color: rgba(255, 255, 255, 0.116);

    text-align: center;
    white-space: initial;
    word-wrap: break-word;
    letter-spacing: 250px;
    line-height: 250px;

    height: 120%;
    width: 200%;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    pointer-events: none;
    z-index: -5;
}

#plusbg_mobile {
    font-size: 25px;
    font-family: 'Work Sans', sans-serif;
    color: rgba(255, 255, 255, 0.116);

    text-align: center;
    white-space: initial;
    word-wrap: break-word;
    letter-spacing: 150px;
    line-height: 150px;

    height: 120%;
    width: 200%;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    pointer-events: none;
    z-index: -5;
}