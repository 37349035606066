.__mb_c_view {
    margin-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    width: 95vw;
    max-width: 465px;
}

.__mb_c_filterbtn {
    background-color: transparent;
    border: 1px solid white;
    color: white;

    font-size: 20px;

    /*95vw */
    width: 97%;
}

.__mb_c_filterbtn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.__mb_c_h2 {
    background-color: #141414;
    margin-top: -8%;
    margin-left: 3%;

    font-size: 30px;

    padding-left: 1%;
    padding-right: 4%;

    display:inline-block;
    white-space: nowrap;
    text-align: center;
    overflow-y: auto;
}

.__mb_c_content {
    background-color: #141414;
    border-width: thin;
    border-style: solid;

    margin: 1vw;

    border-color: #FF9900;

    width: 100%;
    height: 500px;
}

.__mb_c_internal {
    height: 100%;
    width: 100%;
}

.__mb_c_tablehead {
    font-size: 20px;
    height: 5%;
    background-color: #FF9900;
    color: black;

    width: 100%;
}

.__mb_c_pages {
    height: 86%;
    overflow: hidden;
    overflow-y: auto;
}

.__mb_c_item_2 {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
}
.__mb_c_item_4 {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
}

.__mb_c_joinbtn {
    background-color: transparent;
    border: 1px solid white;
    color: white;

    font-size: 20px;

    width: 97%;
}

.__mb_c_joinbtn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.__mb_c_key_input {
    color: white;
    background-color: #141414;
    border: white 1px solid;
    font-size: 20px;
    width: 60vw;
    max-width: 220px;
    height: 30px;

    margin-top: auto;
    margin-bottom: auto;
}

.__mb_c_key_input:focus {
    outline: none;
}

.__mb_c_key_btn {
    color: white;
    background-color: #141414;

    border: solid 1px white;
    width: 20vw;
    max-width: 120px;
    font-size: 20px;

    margin-top: auto;
    margin-bottom: auto;
}
.__mb_c_key_btn:hover {
    color: #ffdda9;
    border-color: #ffdda9;
}

.__mb_c_p_filters {
    border: white 1px solid;
    position: absolute;
    background-color: #141414;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 95vw;
    height: 80vh;
    
    max-width: 465px;
    max-height: 600px;
}

.__mb_mbp_closebtn {
    color: white;
    background-color: transparent;

    border: solid 1px white;

    width: 300px;
    height: 40px;

    font-size: 25px;
}

.__mb_mbp_closebtn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}