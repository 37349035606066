/* Shamelessly copied from: http://aleclownes.com/2017/02/01/crt-display.html */

.crt::after {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: rgba(54, 42, 0, 0.1);
    opacity: 0;
    z-index: 10;
    pointer-events: none;
    animation: flicker 15s infinite;
  }
  .crt::before {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: linear-gradient(rgba(18, 17, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 174, 0, 0.07), rgba(255, 230, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 10;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }
  .crt {
    animation: textShadow 1.6s infinite;
  }

  .crtext {
    animation: textShadow 1.6s infinite;
  }

  .crimg {
    animation: imgShadow 1.6s infinite;
  }

@keyframes flicker {
    0% {
      opacity: 0.27861;
    }
    5% {
      opacity: 0.54769;
    }
    10% {
      opacity: 0.23604;
    }
    15% {
      opacity: 0.90626;
    }
    20% {
      opacity: 0.18128;
    }
    25% {
      opacity: 0.83891;
    }
    30% {
      opacity: 0.65583;
    }
    35% {
      opacity: 0.67807;
    }
    100% {
      opacity: 0.24387;
    }
  }

@keyframes textShadow {
    0% {
      text-shadow: 0.4389924193300864px 0 1px rgba(255, 217, 0, 0.562), -0.4389924193300864px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
    5% {
      text-shadow: 2.7928974010788217px 0 1px rgba(255, 217, 0, 0.562), -2.7928974010788217px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
    10% {
      text-shadow: 0.02956275843481219px 0 1px rgba(255, 217, 0, 0.562), -0.02956275843481219px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
    45% {
      text-shadow: 2.231056963361899px 0 1px rgba(255, 217, 0, 0.562), -2.231056963361899px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
    50% {
      text-shadow: 0.08084290417898504px 0 1px rgba(255, 217, 0, 0.562), -0.08084290417898504px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
    55% {
      text-shadow: 2.3758461067427543px 0 1px rgba(255, 217, 0, 0.562), -2.3758461067427543px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
    60% {
      text-shadow: 2.202193051050636px 0 1px rgba(255, 217, 0, 0.562), -2.202193051050636px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
    100% {
      text-shadow: 2.6208764473832513px 0 1px rgba(255, 217, 0, 0.562), -2.6208764473832513px 0 1px rgba(0, 38, 255, 0.3), 0 0 3px;
    }
}

@keyframes imgShadow {
  0% {
    -webkit-filter: drop-shadow( 0.4389924193300864px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -0.4389924193300864px 0 1px rgba(0, 38, 255, 0.3));
    filter: drop-shadow( 0.4389924193300864px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -0.4389924193300864px 0 1px rgba(0, 38, 255, 0.3));
  }
  5% {
    -webkit-filter: drop-shadow(2.7928974010788217px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.7928974010788217px 0 1px rgba(0, 38, 255, 0.3));;
    filter: drop-shadow(2.7928974010788217px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.7928974010788217px 0 1px rgba(0, 38, 255, 0.3));;
  }
  10% {
    -webkit-filter: drop-shadow(0.02956275843481219px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -0.02956275843481219px 0 1px rgba(0, 38, 255, 0.3));;
    filter: drop-shadow(0.02956275843481219px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -0.02956275843481219px 0 1px rgba(0, 38, 255, 0.3));;
  }
  45% {
    -webkit-filter: drop-shadow(2.231056963361899px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.231056963361899px 0 1px rgba(0, 38, 255, 0.3));;
    filter: drop-shadow(2.231056963361899px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.231056963361899px 0 1px rgba(0, 38, 255, 0.3));;
  }
  50% {
    -webkit-filter: drop-shadow(0.08084290417898504px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -0.08084290417898504px 0 1px rgba(0, 38, 255, 0.3));;
    filter: drop-shadow(0.08084290417898504px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -0.08084290417898504px 0 1px rgba(0, 38, 255, 0.3));;
  }
  55% {
    -webkit-filter: drop-shadow(2.3758461067427543px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.3758461067427543px 0 1px rgba(0, 38, 255, 0.3));;
    filter: drop-shadow(2.3758461067427543px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.3758461067427543px 0 1px rgba(0, 38, 255, 0.3));;
  }
  60% {
    -webkit-filter: drop-shadow(2.202193051050636px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.202193051050636px 0 1px rgba(0, 38, 255, 0.3));;
    filter: drop-shadow(2.202193051050636px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.202193051050636px 0 1px rgba(0, 38, 255, 0.3));;
  }
  100% {
    -webkit-filter: drop-shadow(2.6208764473832513px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.6208764473832513px 0 1px rgba(0, 38, 255, 0.3));;
    filter: drop-shadow(2.6208764473832513px 0 1px rgba(255, 217, 0, 0.562)) drop-shadow( -2.6208764473832513px 0 1px rgba(0, 38, 255, 0.3));;
  }
}