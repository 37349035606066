.chn_o {
    height: 28px;
    font-size: 20px;
    background-color: transparent;
    border: none;

    text-align: left;
    color: #ffdda9;
}

.chn_o:hover {
    background-color: #dda044;
}

.chn_o_chosen {
    background-color: #ad731b;
}

.__c_ico {
    margin-bottom: 5px;
    width: 20px;
}