body {
    font-family: 'VT323', monospace;
    font-size: 24px;
    color: white;

    background-color: #141414;
    user-select: none;
}
.baseCheckbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom checkbox appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 6px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid white;
    background-color: transparent;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    
    &:hover {
        background-color: #ffdda9;
        cursor: pointer;
    }

    &:focus{
        outline: none !important;
    }
}

.baseCheckbox_selected {
    background-color: white;
}

.altCheckbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom checkbox appearance */
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 4px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid black;
    background-color: transparent;

    margin-top: auto;
    margin-bottom: auto;

    &:hover {
        background-color: gray;
        cursor: pointer;
    }

    &:focus{
        outline: none !important;
    }
}

.altCheckbox_selected {
    background-color: black;
}
  
.forcescale_title {
    font-size: 1.5em;
}

.wirePanel {
    background-color: #141414;
    border-width: thin;
    border-style: solid;

    margin: 1vw;
}

.wirePanel_h2 {
    background-color: #141414;
    margin-top: -5%;
    margin-left: 5%;

    font-size: 38px;

    padding-left: 3%;
    padding-right: 4%;

    display:inline-block;
    white-space: nowrap;
    overflow-y: auto;
    text-align: center;
}

.close_btn {
    text-align: center;
    color: white;
    background-color: transparent;
    border-width: 0px;

    font-size: 1.5em;

    width: 1.5em;
    height: 1.5em;
}

.close_btn:hover {
    color: #ffdda9;
}

.textbutton {
    text-align: left;
    color: white;
    background-color: transparent;
    border-width: 0px;

    font-size: 30px;
    
    width: 300px;
}

.textbutton:hover {
    color: #ffdda9;
    font-size: 31px;
}

#backbutton {
    text-align: left;
    color: white;
    background-color: transparent;
    border-width: 0px;

    font-size: 36px;
    
    width: 200px;

    position: absolute;
    top: 60px;
    left: 15px;

    z-index: 50;
}

#backbutton:hover {
    color: #ffdda9;
    font-size: 38px;
}

.ui-btn {
    text-align: left;
    background-color: transparent;
    border-color: white;
    border-style: solid;
    border-width: thin;
    border-width: 0px;

    height: 30px;
    width: 5vh;
}

.ui-btn:hover {
    border-color: #ffdda9;
}

.ui-img {
    width: 100%;
    height: 100%;

    padding: auto;
    margin-bottom: 0.5vh;
    margin-left: 1vw;

    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%);
}

.ui-img:hover {
    filter: invert(95%) sepia(44%) saturate(6990%) hue-rotate(2deg) brightness(107%) contrast(103%);
}

#headertext {
    font-family: 'Krona One', sans-serif;
    color: rgba(134, 134, 134, 0.432);
    font-size: 20px;

    position: fixed;
    top: 40px;
    left: 52vw;
}

#rectangle1 {
    position: fixed;
    top: 0;
    left: 0;

    background: #FF9900;

    width: 100vw;
    height: 25px;
}

#triangle {
    position: fixed;
    top: 0;
    left: 50vw;

    background: #FF9900;

    width: 2vw;
    height: 60px;
    
    clip-path: polygon(0 100%, 100% 0, 0 0);
}

#rectangle2 {
    position: fixed;
    top: 0;
    left: 0;

    background: #FF9900;

    width: 50vw;
    height: 60px;
}

.company_logo {
    height: 4em;
    width: 4em;

    opacity: 0.2;

    z-index: -1;
}

.home_footer {
    font-size:  calc(20px + 1vw);
    color: whitesmoke;
    text-align: left;
    
    position: absolute;
    bottom: 30px;
    left: 3vw;

    pointer-events: none;
}

.image_mask {
    -webkit-mask: 
    linear-gradient(to top,  transparent 10%, #fff 15% 90%, transparent 95%),
    linear-gradient(to left, transparent 10%, #fff 15% 90%, transparent 95%);
    -webkit-mask-size:110% 110%;
    -webkit-mask-position:center;
    -webkit-mask-repeat:no-repeat;
    -webkit-mask-composite: source-in;
    
    
    mask: 
        linear-gradient(to top,  transparent 10%, #fff 15% 90%, transparent 95%),
        linear-gradient(to left, transparent 10%, #fff 15% 90%, transparent 95%);
    mask-size: 110% 110%;
    mask-position: center;
    mask-repeat:no-repeat;
    mask-composite: intersect;
}

.linkbutton {
    background-color: transparent;
    border: none;
}

.linkbutton:hover {
    background-color: transparent;
    border: none;
}

.custom-select {
    position: relative;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid white;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid white;
  top: 55%;
}

select {
    appearance: none;
    /* safari */
    -webkit-appearance: none;
    /* other styles for aesthetics */
    width: 200px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #141414;
    border: 1px solid white;
    color: white;
    cursor: pointer;
}

optgroup {
    font-size: 16px;
    background: #141414;
}

select option {
    appearance: none;
    border-radius: 0px;
    background: #141414;
    color: white;
}

.selectable {
    user-select: text;
}

.selectable::selection {
    background-color: #FF9900;
}
