.chv {
    margin-top: 150px;
    margin-left: 50px;
}

.chv_info {
    width: 600px;
    margin-right: 55px;
}

.chv_about_desc {
    width: 30vw;
    font-size: 24px;

    padding: 5px;
}


.chv_info_btn {
    color: white;
    background-color: #141414;

    border: solid 1px white;

    width: 150px;
    height: 35px;

    font-size: 23px;
}

.chv_info_btn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.chv_info_admbtn {
    color: #FF9900;
    background-color: #141414;

    border: solid 1px #FF9900;

    width: 150px;
    height: 35px;

    font-size: 23px;
}
.chv_info_admbtn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.chv_info_selectedbtn {
    color: #ffdda9;
    border-color: #ffdda9;
}

.chv_img {
    float: right;
    height: 200px;
    width: 200px;
    margin-right: 20px;
}

.chv_dimg {
    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%);
}

.chv_input_label {
    width: 135px;
}

.chv_input {
    color: white;
    background-color: transparent;
    border: solid 1px white;

    width: 80%;
}

.chv_input:focus {
    outline: none;
}

.chv_input_area {
    color: white;
    background-color: transparent;
    border: solid 1px white;
    
    width: 80%;
    height: 250px;
    resize: none;
}

.chv_input_area:focus {
    outline: none;
}

.chv_update_btn {
    color: white;
    background-color: transparent;

    border: solid 1px white;

    padding-left: 15px;
    padding-right: 15px;
}

.chv_update_btn:hover {
    color: #ffdda9;
    border-color: #ffdda9;
}

.__cm_content {
    border-color: #FF9900;

    width: 600px;
    height: 600px;
}

.__cm_list_header {
    font-size: 25px;
    height: 30px;
    background-color: #FF9900;
    color: black;
}

.__cm_list {
    font-size: 24px;
    height: 532px;
    width: 400px;
    overflow-y: auto;
}

.__cm_options {
    font-size: 24px;
    color: black;
    background-color: #FF9900;
    height: 562px;
    width: 200px;
}
.__cm_options_label {
    width: 150px;
}

.__cm_mission_item {
    background-color: transparent;
    border: none;
    text-align: left;
    color: white;
}

.__cm_mission_item:hover {
    background-color: #ffdda9;
    color: black;
}

.__cm_mission_item__complete {
    color: #00cc66 !important;
}

.__cm_mission_item__current {
    color: #0096FF !important;
}

.__cm_btn {
    color: white;
    background-color: #141414;

    border: solid 1px white;

    width: 150px;
    height: 35px;

    font-size: 23px;
}
.__cm_btn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.__cnews_content {
    border-color: white;

    width: 400px;
    height: 350px;
}

.__cnews_internal {

}

.__chv_item_0 {
    width: 10px;
    overflow: hidden;
    white-space: nowrap;
}
.__chv_item_1 {
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
}
.__chv_item_2 {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
}
.__chv_item_3 {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
}
.__chv_item_4 {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}