.profile_content {
    margin-top: 150px;
    margin-left: 50px;
}

#handler_container {
    white-space: nowrap;
}

#handler_name {
    color: #ffdda9;
    overflow: hidden;
    white-space: nowrap;
}

.pilot_option_panel {
    margin-top: 50px;
    width: 650px;
}

.pilot_pilot_panel {
    border: 1px solid white;
    overflow: auto;

    height: 650px;
    width: 780px;
}

.pilot_option_button {
    text-align: left;
    color: white;
    background-color: transparent;
    border-width: 0px;

    font-size: 30px;
    
    width: 100%;
}

.pilot_option_button:hover {
    color: #ffdda9;
    font-size: 31px;
}

.handler_ico_container {
    height: 35px;

    background-color: transparent;
    border: none;

    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%) opacity(95%);
}

.handler_ico_container:hover {
    filter: invert(95%) sepia(44%) saturate(3000%) hue-rotate(2deg) brightness(107%) contrast(103%) opacity(80%);
}

.handler_ico_btn {
    height: 30px;
    width: 50px;

    margin-bottom: 9px;
}


#handler_name_input {
    background-color: transparent;
    border-color: white;
    color: white;
}