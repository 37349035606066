.channel_c_create {
    margin-top: 150px;
    margin-left: 50px;
}

.channel_c_form {
    width: 40vw;
}

.channel_c_form_input {
    max-height: 32px;

    background-color: transparent;
    border: none;

    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: white;

    background-color: #141414;
    color: white;
}

.channel_c_form_title_input {
    font-size: 40px;
    max-height: 40px;
}

.channel_c_form_input:focus {
    outline: none;
}
.channel_c_form_input::selection  {
    background: #FF9900;
}

.channel_c_form_tarea {
    font-size: 20px;
    height: 400px;
    resize: none;
    background-color: transparent;

    border-style: solid;
    border-width: thin;
    border-color: white;

    color: white;
    background-color: #141414;
}

.channel_c_form_tarea:focus {
    outline: none;
}

.channel_c_form_tarea::selection {
    background: #FF9900;
}

.__co_content {
    border-color: white;

    width: 450px;
    height: 600px;    
}

.channel_c_checkbox_container {
    font-size: 30px;
}

.channel_c_img_container {
    font-size: 30px;
}

.channel_c_img_input {
    font-size: 16px;
    width: 80%;

    color:white;

    background-color: #141414;
    border: none;
    border-bottom: solid 1px white;
}

.channel_c_img_input:focus {
    outline: none;
}

.channel_c_img_input::selection {
    background: #FF9900;
}

.channel_c_img_button {
    text-align: left;
    color: white;
    background-color: transparent;
    border-width: 0px;

    font-size: 26px;
    margin: auto;
}

.channel_c_img_button:hover {
    color: #ffdda9;
}


/* It's confusing but this refers to the actual image of the image box */
.channel_c_img_img {
    width: 275px;
    height: 275px;

    margin: auto;
}

.channel_c_img_default {
    width: 275px;
    height: 275px;

    margin: auto;
    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%);
    z-index: 5;
}