.mb_chv {
    margin-top: 60px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    width: 95vw;
    max-width: 465px;
}

.mb_chv_title {
    overflow: hidden;
    /* width: 90vw; */

    padding-bottom: 10px;
}

.mb_chv_cimg {
    /* float: right; */
    height: 75px;
    width: 75px;
}

.mb_chv_dimg {
    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%);
}

.mb_chv_mobileview {
    border: 1px solid white;
    width: 100%;
    height: 65vh;
    min-height: 500px;

    border-top: none;

    background-color: #141414;
}

.mb_chv_tabbtn {
    background-color: transparent;
    border: 1px solid white;
    color: white;

    font-size: 20px;

    /*95vw */
    width: 23.75vw;
}

.mb_chv_tabbtn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.mb_chv_curtab {
    border-bottom: none !important;
}

.mb_chm_content {
    width: 100%;
    height: 100%;
}

.mb_chm_fullcol {
    width: 100%;
}

.mb_chm_list_header {
    font-size: 20px;
    height: 24px;
    background-color: whitesmoke;
    color: black;

    width: 100%;
}

.mb_chm_list {
    font-size: 22px;
    height: 532px;
    overflow-y: auto;
}

.mb_chm_mission_item {
    background-color: transparent;
    border: none;
    text-align: left;
    color: white;
    width: 100%;
}

.mb_chm_mission_item:hover {
    background-color: #ffdda9;
    color: black;
}

.mb_chm_item_2 {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
}
.mb_chm_item_4 {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
}

.mb_chm_mission_item__complete {
    color: #00cc66 !important;
}

.mb_chm_mission_item__current {
    color: #0096FF !important;
}

.mb_chv_about_container {
    padding: 15px;

    height: 100%;
    overflow: scroll;
}

.mb_chv_about_desc {
 font-size: 24px;
 /* overflow-y: scroll; */
}