.mb_profile_content {
    margin-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    width: 95vw;

    max-width: 465px;
}

.mb_handler_ico_btn {
    height: 20px;
    width: 20px;

    margin-bottom: 9px;
}

#mb_handler_container {
    white-space: nowrap;
    width: 90vw;
    /* font-size: 26px !important; */
}

#mb_handler_name {
    /* font-size: 24px; */
    color: #ffdda9;

    overflow: hidden;
    white-space: nowrap;

    max-width: 160px;
    margin-top: auto;
    margin-left: 16px;
}

#mb_handler_name_input {
    font-size: 22px;
    background-color: transparent;
    border-color: white;
    color: white;

    width: 130px;
}

.mb_pilot_pilot_panel {
    border: 1px solid white;
    overflow: auto;

    height: 70vh;
    width: 100%;

    margin: auto;
}

.mb_pilot_option_button {
    text-align: left;
    color: white;
    background-color: transparent;
    border-width: 0px;

    font-size: 26px;
    
    width: 100%;
}

.mb_pilot_option_button:hover {
    color: #ffdda9;
    font-size: 26.5px;
}

.mb_pilot_upload_btn {
    border: 1px solid white;
    color: white;
    background-color: transparent;
    font-size: 22px;
    height: 34px;

    width: 100px;
}

.mb_pilot_upload_btn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}