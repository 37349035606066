.mission_view {
    margin-top: 150px;
    margin-left: 50px;
}

.mv_info_container {
    width: 30vw;
    min-width: 300px;
}

.mv_desc {
    font-size: 24px;
    height: 60vh;
    overflow: auto;
}

.__md_content {
    width: 400px;
    height: 650px;
}

.__md_widen {
    width: 780px;
}

.__md_internal {
    height: 90%;
}

.__md_internal_members {
    height: 90%;
}

.__md_pilot_container {
    height: 100%;
    overflow: auto;
}

.__md_subcon {
    width: 100%;
    min-width: 300px;
}

.md_extra_label {
    color: white;
    font-size: 28px;
    pointer-events: all;
}

.md_extra_value {
    color: #ffdda9;;
    font-size: 28px;
}

.md_smaller_text {
    font-size: 20px !important;
    margin-top: auto;
    margin-bottom: auto;
}

.__md_back_btn {
    height: 40px;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    width: 300px;

    margin-bottom: 20px;
}

.__md_back_btn:hover {
    outline: none;
    border: 1px #ffdda9 solid;
    color: #ffdda9;
}

.__md_join_btn {
    height: 40px;
    color: white;
    background-color: transparent;
    border: 1px solid white;
}

.__md_join_btn_adm {
    height: 40px;
    color: #FF9900;
    background-color: transparent;
    border: 1px solid #FF9900;
}

.__md_join_btn:hover {
    outline: none;
    border: 1px #ffdda9 solid;
    color: #ffdda9;
}

.__md_join_btn_adm:hover {
    outline: none;
    border: 1px #ffdda9 solid;
    color: #ffdda9;
}

.mv_pilot_select {
    position: absolute;
    border: 1px solid white;

    background-color: #141414;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    height: 650px;
    width: 780px;
}

.mv_pilot_scroll {
    height: 80%;
    overflow: auto;
}

.mv_cancel_pilot_select {
    height: 40px;
    color: white;
    background-color: transparent;
    border: 1px solid white;
}

.mv_cancel_pilot_select:hover {
    outline: none;
    border: 1px #ffdda9 solid;
    color: #ffdda9;
}