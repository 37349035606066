@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Exo+2&family=Krona+One&family=Open+Sans&family=Roboto:wght@100&family=VT323&family=Work+Sans&display=swap');

/* font-family: 'Comfortaa', cursive;
font-family: 'Open Sans', sans-serif;
font-family: 'VT323', monospace;
font-family: 'Work Sans', sans-serif; */

/** Classes for the displayed toast **/
.Toastify__toast {
    background-color: #14141463;
    color: white;
    border-radius: 0px;
    border: 1px white solid;

    font-family: 'VT323', monospace;
}