.sp_header {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 52, 131, 0.9);

    user-select: none;
}

.sp_header_solid {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 52, 131);

    user-select: none;
}

#sp_header_bg {
    /* position: absolute; */
    width: 100%;
    height: 80px;

    font-family: 'Krona One', sans-serif;
    font-size: 32.5px;
}

#sp_header_logo {
    height: 75px;
    filter: sepia(100%) saturate(200%) brightness(525%) hue-rotate(180deg);
}

.sp_header_nav {
    color: white;
    background-color: transparent;

    border-top-width: 0px;
    border-bottom-width: 0px;

    max-height: 50px;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 16px;
}
.sp_noright {
    border-right-width: 0px;
}

.sp_header_nav:hover {
    background-color: rgba(128, 128, 128, 0.192);
}

#sp_extra_border {
    height: 1vh;
}