.channel_content {
    margin-top: 150px;
    margin-left: 50px;
}

.channel_key_input {
    color: white;
    background-color: #141414;
    border: white 1px solid;
    font-size: 20px;
    width: 200px;
    height: 30px;

    margin-top: auto;
    margin-bottom: auto;
}
.channel_key_input:focus {
    outline: none;
}

.channel_key_btn {
    color: white;
    background-color: #141414;

    border: solid 1px white;
    width: 100px;
    font-size: 20px;

    margin-top: auto;
    margin-bottom: auto;
}
.channel_key_btn:hover {
    color: #ffdda9;
    border-color: #ffdda9;
}

.__c_content {
    border-color: #FF9900;

    width: 800px;
    height: 70vh;
}

.__i_content {
    border-color: white;

    width: 400px;
    height: 550px;
}

.__i_internal {
    font-size: 30px;
    height: 90%;
}

.__i_search_btn {
    color: white;
    background-color: transparent;

    border: solid 1px white;

    width: 300px;
    height: 40px;

    font-size: 25px;
}

.__i_search_btn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.__i_subpanel {
    font-size: 26px;
    border: solid 1px #FF9900;
}

.__i_subpanel_content {
    padding-left: 10px;
}
.__i_subpanel_vpc {
    padding-left: 10px;
}

.__i_search_input {
    background-color: transparent;
    font-size: 26px;

    height: 30px;

    color: white;

    border: none;
    border-bottom: solid 1px white;
}

.__i_search_input:focus {
    outline: none;
}

.__c_internal {
}

.__c_tablehead {
    font-size: 20px;
    background-color: #FF9900;
    border: none;

    text-align: left;
    color: black;
}

.__c_pages {
    
}

.__c_item_0 {
    width: 10px;
    overflow: hidden;
    white-space: nowrap;
}
.__c_item_1 {
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
}
.__c_item_2 {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
}
.__c_item_3 {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
}
.__c_item_4 {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}