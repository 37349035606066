.create_mission {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 70vw;
    height: 80vh;

    background-color: #141414;
    border: solid 1px white;

    z-index: 9;

    overflow: auto;
}

.create_mission_content {
    height: 100%;
}

.create_mission_textcontainer {
    width: 50vw;
    height: 55vh;

    min-width: 300px;
}

.create_mission_btn {
    border: 1px solid white;
    background-color: transparent;
    color: white;

    font-size: 30px;
}

.create_mission_btn:hover {
    color: #ffdda9;
    border-color: #ffdda9;
}

.create_mission_textinput {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px white;
    color: white;

    width: 97.5%;

    font-size: 28px;
}

.create_mission_textinput:focus {
    outline: none;
}

.create_mission_textarea {
    background-color: transparent;
    color: white;
    border: solid 1px white;
    font-size: 22px;
    resize: none;
    width: 97.5%;

    height: 68%;
    overflow-y: auto;
}

.create_mission_textarea:focus {
    outline: none;
}

.create_mission_misccontainer {
    text-align: center;
    height: 55vh;
}

.create_mission_label {
    font-size: 26px;
}

.create_mission_num {
    font-size: 20px;
    min-width: 150px;
    min-height: 35px;
    background-color: transparent;
    color: white;

    border: none;
    border-bottom: 1px white solid;

    text-align: left;
}

.create_mission_num:focus {
    outline: none;
}
.create_mission_label_clientname {
    font-size: 40px;
}

.create_mission_dt {
    font-size: 16px;
    background-color: transparent;
    color: white;
    min-width: 200px;
    min-height: 35px;

    border: 1px solid white;
}

.create_mission_dt:focus {
    outline: none;
}