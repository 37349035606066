#mb_rec1 {
    position: absolute;
    top: 0;
    left: 0;

    background: #FF9900;

    width: 100vw;
    height: 25px;

    color: white;
}

#mb_triangle {
    position: absolute;
    top: 0;
    left: 60vw;

    background: #FF9900;

    width: 16vw;
    height: 50px;
    
    clip-path: polygon(0 100%, 100% 0, 0 0);
}

#mb_rec2 {
    position: absolute;
    top: 0;
    left: 0;

    background: #FF9900;

    width: 60vw;
    height: 50px;
}

#mb_headertext {
    font-family: 'Krona One', sans-serif;
    color: rgba(134, 134, 134, 0.432);
    font-size: 12px;

    position: absolute;
    top: 35px;
    left: 68vw;
}

#mb_title {
    /* margin-top: 0px; */
    margin-top: 18px;
    color: black;
    font-size: 24px;
    margin-left: 10px;
    margin-right: 10px;

    text-align: left;
    width: 95%;

    position: absolute;
}
