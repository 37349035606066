.mobile_home {
    margin-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    width: 95vw;
    max-width: 465px;
}

.mb_wirePanel_h2 {
    background-color: #141414;
    margin-top: -8%;
    margin-left: 3%;

    font-size: 30px;

    padding-left: 1%;
    padding-right: 4%;

    display:inline-block;
    white-space: nowrap;
    overflow-y: auto;
    text-align: center;
}

.__mb_m_content {
    border-color: #FF9900;
    
    width: 100%;
    height: 100vh;
}

.__mb_m_fullcol {
    width: 100%;
}

.__mb_m_list_header {
    font-size: 20px;
    height: 24px;
    background-color: #FF9900;
    color: black;

    width: 100%;
}

.__mb_m_list {
    font-size: 22px;
    height: 532px;
    overflow-y: auto;
}

.__mb_m_mission_item {
    background-color: transparent;
    border: none;
    text-align: left;
    color: white;
    width: 100%;
}

.__mb_m_mission_item:hover {
    background-color: #ffdda9;
    color: black;
}

/* .__mb_m_options {
    font-size: 24px;
    color: black;
    background-color: #FF9900;
    
    height: 612px;
    width: 240px;
}
.__mb_m_options_label {
    width: 175px;
} */

.__mb_m_item_2 {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
}
.__mb_m_item_4 {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
}

.__mb_n_content {
    border-color: white;

    width: 90vw;
    height: 400px;
}

.__mb_m_mission_item__complete {
    color: #00cc66 !important;
}

.__mb_m_mission_item__current {
    color: #0096FF !important;
}