.mail_content {
    margin-top: 15vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.__inb_content {
    border-color: #FF9900;
    
    width: 60vw;
    height: 70vh;
}

.__mm_content {
    border-color: white;

    width: 20vw;
    height: 60vh;
}