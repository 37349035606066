/* FOOTER */
#sp_footer {
    min-height: 200px;
    height: 15vh;
    background-color: #008dff;
    font-size: 12px;
}

.sp_a {
    color: #f3f0e9;
}

.sp_h3 {
    font-size: 16px;
}