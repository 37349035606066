.chv_subpage_container {
    overflow-y: auto;
    max-height: 500px;
    width: 100%;
}

.chv_members_header {
    position: relative;
    background-color: #FF9900;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
}

.chv_about_container {
    height: 100%;
}

.chv_member_container {
    height: 100%;
}

.chv_manage_container {
    height: 100%;
}

.chv_member_submenu {
    padding-left: 10px;
    padding-right: 10px;

    width: 100%;
    height: 250px;

    border: #FF9900 1px solid;
    border-top: none;

    overflow: auto;
}

.chv_members_item {
    border: none;
    background-color: transparent;
    color: white;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;

    width: 100%;
}

.chv_members_item:hover {
    background-color: #ffdda9;
    color: black;
}

.chv_members_item_expanded {
    border: #FF9900 1px solid;
    border-bottom: none;

    background-color: #ffdda9;
    color: black;
}

.chv_members_permsbox {
    margin-top: 20px;
    height: 200px;
    padding: 5px;
    border: white 1px solid;

    overflow: auto;
    width: 50%;
}
.chv_permset {
    width: 100%;
}

.chv_buttonbox {
    margin-top: 20px;
    height: 200px;
    padding: 5px;
    border: white 1px solid;
    overflow: auto;
    width: 50%;
}

.chv_mem_button {
    background-color: transparent;
    border: none;
    color: white;

    text-align: left;
}

.chv_mem_button:hover {
    color:#ffdda9;
}