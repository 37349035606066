.sp_about {
    width: 100vw;

    font-family: 'Comfortaa', sans-serif;

    background-color: white;
}

.sp_ab_title {
    font-family: 'Krona One', sans-serif;
    font-size: 48px;
}

.sp_ab_textcontent {
    color: black;

    user-select: text;
    margin-top: 80px;
    margin-left: 20px;
    height: 75vh;
    max-width: 600px;

    font-size: 20px;
}