/* Splash Blue: #008dff */

.mainsplash {
    font-family: 'Work Sans', sans-serif;
    color: white;

    background-color: transparent;
    user-select: text;
}

#sp_main_img {
    width: 100%;
    height: 90vh;

    min-height: 800px;
    object-fit: cover;

    z-index: -1;
}


#enter_btn {
    width: 10em;
    height: 3em;

    font-size: 0.75em;
}

/* AUTH MENU */
#auth_menu {
    position: absolute;

    top: 350px;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 350px;
    height: 450px;

    font-size:calc(2px + 1vw);

    margin: auto;

    background-color: transparent;
    border-radius: 10px;
}

#auth_menu_bypass {
    position: absolute;

    top: 350px;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 350px;
    height: 250px;

    font-size:calc(2px + 1vw);

    margin: auto;

    background-color: transparent;
    border-radius: 10px;
}

#enter_site_btn {
    margin: auto;
    background-color: #008dff;
    border: none;
    border-radius: 15px;
    font-size: 26px;
    width: 200px;
    height: 50px;

    color: white;
}
#enter_site_btn:hover {
    background-color: #0065b8;
}

#sp_main_text {
    user-select: none;
    position: absolute;

    top: 150px;
    left: 50%;

    width: 300px;

    transform: translate(-50%, -50%);

    text-align: center;

    font-size: 24px;

    font-family: 'Krona One', sans-serif;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
}

#auth_container {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;

    width: 80%;
    height: 100%;

    /* background-color: rgb(233, 237, 243); */
    border-radius: 10px;
}

.auth_label {
    margin: auto;

    color: black;
    font-family: 'Comfortaa', sans-serif;
    font-size: 20px;

    width: 250px;
}

.auth_input {
    margin: auto;
    border-radius: 5px;
    font-family: 'Comfortaa', sans-serif;
    width: 250px;

    font-size: 20px;
}

.auth_blue_btn {
    font-family: 'Comfortaa', sans-serif;
    font-size: 16px;
    
    margin-left: 25%;
    height: 40px;
    color: white;
    background-color: #008dff;
    border-radius: 15px;
    width: 50%;
    margin: auto;

    border: none;
}

.auth_gray_btn {
    font-family: 'Comfortaa', sans-serif;
    font-size: 16px;
    
    height: 40px;
    color: black;
    background-color: rgb(238, 238, 238);
    border-radius: 15px;
    width: 50%;
    margin: auto;

    border: none;
}

.auth_signin_txt {
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    color: rgb(58, 58, 58);

    margin:auto;
}

.auth_signin_txt_specific {
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    color: #008dff;
}

.auth_blue_btn:hover {
    background-color: #0069be;
}

.auth_gray_btn:hover {
    background-color: rgb(216, 216, 216);
}

.auth_border {
    background-color: #008dff;
}

.auth_b_t {
    height: 60px;

    text-align: center;
    font-family: 'Comfortaa', sans-serif;
    font-size: 30px;

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.auth_b_b {
    height: 20px;

    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

/* INFO PANEL */
#org_info {
    /* background-color: #008dff; */
    color: black;

    width: 60%;
    margin: auto;
    margin-top: 20px;
}

.sp_info_container {
    background-color: #f3f0e9;
}

.sp_info_textbox {
    padding: 5px;
    padding-top: 30px;

    width: 50%;
}

.sp_textbox_mobile {
    width: 100%;
    align-items: center;
}

.sp_info_title {
    font-size: calc(16px + 0.5em);
    font-family: 'Krona One', sans-serif;
}

.sp_info_text {
    font-size: calc(13px + 0.15em);
    font-family: 'Comfortaa', sans-serif;
}

.sp_info_img {
    width: 50%;
    object-fit: cover;  
}

.sp_img_mobile {
    width: 100% !important;
}

.org_set {
    background-color: #f3f0e9;
}