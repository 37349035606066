.ch_prv {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 700px;
    height: 700px;

    border: solid 1px #FF9900;
    background-color: #141414;

    z-index: 5;
}

.cp_title {
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
}

.cp_img {
    float: right;
    height: 200px;
    width: 200px;
    margin-right: 20px;
}

.cp_dimg {
    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%);
}

.cp_desc {
    width: 100%;
    min-height: 550px;
}

.cp_btn {
    color: white;
    width: 200px;
    border: solid 1px white;
    background-color: transparent;
}

.cp_btn:hover {
    border-color: #ffdda9;
    color: #ffdda9;
}

.cp_inpt {
    border: 1px solid white;
    background-color: #141414;

    color: white;
    width: 200px;
}

.cp_inpt:focus {
    outline: none;
}