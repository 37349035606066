.pilotview_content {
    border: thin solid white;
    background-color: rgb(40, 40, 40);

    width: 725px;
    height: 325px;

    word-break: keep-all;

    overflow: hidden;
    white-space: nowrap;

    z-index: 500;
}

.pilotview_pfphalf {
    width: 250px;
}

.vertline {
    border-left: thin solid white;
    height: 100%;
}

.pilot_img {
    filter: sepia(100%) saturate(200%) brightness(125%) hue-rotate(180deg);

    height: 200px;
    width: 200px;
}

.barcode {
    filter: sepia(100%) saturate(100%) brightness(155%) hue-rotate(200deg);

    height: 50px;
    width: 200px;
}

.pilot_info_col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 50%;
}

.pilot_title_text {
    font-size: 24px;
    z-index: 1;
}

.pilot_info_text {
    font-size: 20px;
}

.pilot_info_text_license {
    font-size: 24px;
    text-align: center;

    z-index: 1;
}

.pilot_stat_text {
    font-size: 16px;
    z-index: 2;
}
.id_text {
    white-space: nowrap;
    font-size: 12.5px;
}

.hex_img {
    filter: sepia(100%) saturate(200%) brightness(205%) hue-rotate(180deg);
    width: 30px;

    pointer-events: none;
    z-index: 2;
}

.rank_img {
    filter: sepia(90%) saturate(90%) brightness(55%) hue-rotate(20deg);
    position: fixed;

    width: 200px;
    pointer-events: none;
    opacity: 0.6;
}

.mb_pilotview_content {
    border: thin solid white;
    background-color: rgb(40, 40, 40);

    width: 100%;
    height: 200px;

    max-width: 420px;

    word-break: keep-all;

    overflow: hidden;
    white-space: nowrap;
}

.mb_mv_pilot_select {
    width: 90vw !important;
    max-width: 450px;
}

.mb_pilot_img {
    filter: sepia(100%) saturate(200%) brightness(125%) hue-rotate(180deg);

    height: 60px;
    width: 60px;
}

.mb_id_text {
    white-space: nowrap;
    font-size: 12.5px;
}

.mb_name_text {
    font-size: 24px;
}

.mb_info_text {
    font-size: 18px;
}

.mb_lvltext {
    /* position: */
    font-size: 20px;
    margin-left: auto;
}

.pilotview_content_btn:hover {
    border: thin solid #FF9900;
    background-color: rgb(58, 58, 58);

    color: #FF9900;
    cursor: pointer;
}
