.mb_rectangle {
    background-color: #FF9900;
    height: 50px;
    width: 100%;
    bottom: 0px;

    overflow: hidden;

    position: fixed;
    
    transition: all 0.3s;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    max-width: 500px;
}

.mb_rectangle_close {
    top: 100%;
    height: 0;

    overflow: hidden;

    font-size: 0;
}

.mb_btn {
    height: 45px;
    width: 30vw;
    background-color: transparent;
    border: 0px solid black;
}

.mb_btnborder {
    border-left: 1px solid black;
}

.mb_btn:hover {
    border-color: white;
    filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%);
}

.mb_icon {
    height: 70%;
    width: 90%;

    /* filter: invert(500%) sepia(500%) saturate(3000%) hue-rotate(50deg) brightness(500%) contrast(500%); */
}